<template>
    <div :class="containerClass" class="flex top justify-end z-2000 hamburger-and-close">
        <div>
            <!-- Open -->
            <div class="flex items-center cursor-pointer" ref="openButton" @click="$emit('open-nav')" v-if="!showing">
                <span class="hidden mr-4 cursor-pointer md:inline">Menu</span>
                <button type="button" class="focus:outline-none border-none" aria-label="Close">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.465 283.465" class="w-8 h-8"><path fill="none" d="M0 0h283.465v283.465H0z"/><path fill="#1d1d1b" d="M14.885 54.228H268.58v13.426H14.885zM14.885 215.81H268.58v13.426H14.885zM14.885 135.019H268.58v13.426H14.885z"/></svg>
    			</button>
            </div>

            <!-- Close -->
            <div v-else class="flex items-center cursor-pointer" @click="$emit('close-nav')">
                <button ref="closeButton" type="button" class="focus:outline-none border-none" aria-label="Close">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.46 283.46" class="w-8 h-8"><path fill="none" d="M0 0h283.46v283.46H0z"/><path fill="#1d1d1b" d="M151.05 141.73l110-110a6.59 6.59 0 10-9.31-9.32l-110 110-110-110a6.59 6.59 0 00-9.32 9.32l110 110-110 110a6.59 6.59 0 009.33 9.27l110-110 110 110a6.58 6.58 0 109.31-9.31z"/></svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {

    props: {
        showing: {
            required: true,
            type: Boolean
        }
    },
    computed: {
        containerClass() {
            return this.showing ? 'fixed top-[58px] right-5' : 'relative';
        }
    }
}
</script>
